import StrapiImage, { StrapiImageProps } from "@components/common/StrapiImage";
import { ComponentBlocksImage } from "@lib/generated/graphql";
import clsx from "clsx";
import { FC } from "react";

interface ImageProps {
  image: ComponentBlocksImage | null;
  roundedClass?: string;
}

const ImageBlock: FC<ImageProps & Omit<StrapiImageProps, "image">> = ({
  image,
  className,
  width,
  roundedClass,
  ...props
}) => {
  if (!image) return null;

  // The flex wrapper is necessary to be able to add rounded border to the image.
  const objectClass = `object-${image.fit} object-${image.position}`;
  const isContain = image.fit === "contain";
  const styles = image.style?.split("_");
  const imageClasses = [objectClass];

  if (styles?.includes("shadow")) {
    imageClasses.push("shadow-3");
  }

  if (styles?.includes("border")) {
    imageClasses.push("border border-neutral-50");
  }

  if (styles?.includes("rounded")) {
    imageClasses.push(roundedClass || "rounded-m");
  }

  return (
    <div
      className={clsx(
        "relative max-h-[50vh] m:max-h-full w-full h-full flex items-center justify-center",
        isContain && {
          "m:items-end m:justify-center": image.position === "bottom",
          "m:items-end m:justify-end": image.position === "bottom_right",
          "m:items-end m:justify-start": image.position === "bottom_left",
          "m:items-start m:justify-center": image.position === "top",
          "m:items-start m:justify-end": image.position === "top_right",
          "m:items-start m:justify-start": image.position === "top_left",
          "m:justify-end m:items-center": image.position === "right",
          "m:justify-start m:items-center": image.position === "left",
        }
      )}
    >
      <StrapiImage
        {...props}
        width={width}
        image={image.image}
        className={clsx(
          "max-w-full max-h-[50vh] m:max-h-full",
          className,
          ...imageClasses,
          {
            "h-full w-full": image.fit === "cover",
          }
        )}
      />
    </div>
  );
};

export default ImageBlock;
