import Footer from "@components/blocks/Footer";
import Container from "@components/common/Container";
import NavBar from "@components/common/NavBar";
import { logger } from "@justhome/common";
import { config } from "@lib/config";
import {
  ComponentSectionsCtaWithImageV3,
  UploadFile,
} from "@lib/generated/graphql";
import getNavigation from "@lib/strapi/getNavigation";
import CtaWithImageV3 from "@modules/homepage/sections/CtaWithImageV3";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useRouter } from "next/router";
import { useEffect, useMemo } from "react";

const NotFoundPage = ({ navigation }) => {
  const { t } = useTranslation();
  const router = useRouter();

  useEffect(() => {
    logger.error(
      "Page not found",
      new Error(`Path /${router.locale}${router.asPath} not found`),
    );
  }, [router.asPath, router.locale]);

  const data: ComponentSectionsCtaWithImageV3 = useMemo(() => {
    return {
      __typename: "ComponentSectionsCtaWithImageV3",
      id: "1",
      layout: {
        id: "1",
        direction: "normal",
        firstColumn: 6,
        secondColumn: 5,
      },
      text: {
        id: "1",
        title: t("404_title"),
        subTitle: t("404_sub_title"),
        button: {
          id: "1",
          label: t("404_button_label"),
          variant: "primary",
          size: "lg",
          type: "button",
          href: "/",
        },
      },
      image: {
        id: "1",
        paddingBottom: "md",
        paddingTop: "md",
        image: {
          id: "1",
          fit: "contain",
          position: "center",
          style: "none",
          image: {
            data: {
              id: "1",
              attributes: {
                width: 400,
                height: 400,
                url: "/assets/img/404-v2.png",
              } as UploadFile,
            },
          },
        },
      },
    } as ComponentSectionsCtaWithImageV3;
  }, [t]);

  return (
    <>
      <NavBar data={navigation} />
      <Container>
        <CtaWithImageV3 index={0} data={data} />
      </Container>
      <div className="max-w-[1600px] mx-auto">
        <Footer />
      </div>
    </>
  );
};

export default NotFoundPage;

export async function getStaticProps({ locale }: { locale: string }) {
  const navigation = await getNavigation(locale);
  return {
    props: {
      navigation,
      ...(await serverSideTranslations(locale)),
    },
    revalidate: config.cms.revalidate,
  };
}
