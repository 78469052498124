import Button from "@components/blocks/Button";
import CardButton from "@components/blocks/CardButton";
import RichText from "@components/common/RichText";
import StrapiImage from "@components/common/StrapiImage";
import { Icon, Title } from "@justhome/react-ui";
import { ComponentBlocksSectionText, Maybe } from "@lib/generated/graphql";
import clsx from "clsx";
import { FC, ReactElement } from "react";

interface TextPartProps {
  data?: Maybe<ComponentBlocksSectionText>;
  serifClass?: string;
  mobileSlot?: ReactElement;
  index?: number;
  headlineSize?: "md" | "lg";
  fullWidthButton?: boolean;
}

const SectionText: FC<TextPartProps> = ({
  data,
  serifClass,
  mobileSlot,
  index,
  headlineSize = "lg",
  fullWidthButton = false,
}) => {
  if (!data) {
    return null;
  }

  return (
    <div
      className={clsx("flex h-full flex-col", {
        "justify-center": !data.cardButton,
      })}
    >
      {data.mobileLayout === "top" && (
        <div className="m:hidden">{mobileSlot}</div>
      )}
      {data.overlineImage?.data && (
        <div className="relative h-[120px] w-[340px] m:h-[200px] my-m">
          <StrapiImage
            fill
            sizes="340px"
            image={data.overlineImage}
            className="object-contain object-left"
          />
        </div>
      )}
      <div className="mt-m m:mt-none">
        {data.overline && (
          <Title
            as="span"
            className="text-overline-1-serif"
            serifClass={serifClass}
          >
            {data.overline}
          </Title>
        )}
        {data.title && (
          <Title
            className={
              headlineSize === "lg" ? "text-d3 mb-3s" : "text-h2 mb-3s"
            }
            serifClass={serifClass}
            as={index === 0 ? "h1" : "h2"}
          >
            {data.title}
          </Title>
        )}
      </div>

      {data.mobileLayout === "afterTitle" && (
        <div className="m:hidden">{mobileSlot}</div>
      )}

      {data.body && <RichText className="mb-m">{data.body}</RichText>}
      {!!data.list?.length && (
        <ul className="space-y-3xs flex-1 mb-m">
          {data.list?.map((item, index) => (
            <li className="flex" key={index}>
              {item?.icon && <Icon className="mr-2xs" type={item?.icon} />}
              <div className={clsx("text-body-2")}>{item?.text}</div>
            </li>
          ))}
        </ul>
      )}

      {data.mobileLayout === "afterBody" && (
        <div className="m:hidden">{mobileSlot}</div>
      )}
      {data.button && (
        <Button
          className={clsx("mb-m m:mb-none", {
            "!w-full": fullWidthButton,
          })}
          {...data.button}
        />
      )}

      {data.cardButton && (
        <div className="mt-xl flex-1 flex items-end mb-m m:mb-none">
          <CardButton {...data.cardButton} />
        </div>
      )}
      {data.mobileLayout === "bottom" && (
        <div className="m:hidden">{mobileSlot}</div>
      )}
    </div>
  );
};

export default SectionText;
