import Section from "@components/common/Section";
import { getSerifClass } from "@components/common/utilities";
import { ComponentSectionsCtaWithImageV3 } from "@lib/generated/graphql";
import { FC } from "react";
import SectionImage from "../../../../components/common/SectionBlocks/SectionImage";
import SectionText from "../../../../components/common/SectionBlocks/SectionText";
import TwoColumnLayout from "../../../../components/common/SectionBlocks/TwoColumnLayout";

interface CtaWithImageV3Props {
  data: ComponentSectionsCtaWithImageV3;
  index: number;
}

const CtaWithImageV3: FC<CtaWithImageV3Props> = ({ data, index }) => {
  const serifClass = getSerifClass(data.background);
  return (
    <Section section={data} className="my-2s m:my-3s">
      <TwoColumnLayout data={data.layout}>
        <TwoColumnLayout.FirstColumn data={data.layout} className="m:py-3xl">
          <SectionText
            index={index}
            serifClass={serifClass}
            data={data.text}
            mobileSlot={<SectionImage index={index} data={data.image} />}
          />
        </TwoColumnLayout.FirstColumn>
        <TwoColumnLayout.SecondColumn
          data={data.layout}
          className="hidden m:flex"
        >
          <SectionImage data={data.image} index={index} />
        </TwoColumnLayout.SecondColumn>
      </TwoColumnLayout>
    </Section>
  );
};

export default CtaWithImageV3;
