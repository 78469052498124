"use client";
import { cva, VariantProps } from "class-variance-authority";
import { FC, MouseEventHandler, PropsWithChildren } from "react";
import Link from "../internal/Link";
import CardButtonBody from "./CardButtonBody";
import CardButtonFooter from "./CardButtonFooter";
import CardButtonTitle from "./CardButtonTitle";
import { CardButtonContext } from "./utilities";

const buttonStyles = cva(
  "group text-left flex flex-col justify-between min-w-[auto] m:min-w-[320px] transition cursor-pointer focusable",
  {
    variants: {
      variant: {
        outline_primary:
          "hover:text-primary-800 border border-neutral-50 hover:border-primary-800 bg-neutral-0 hover:bg-neutral-12 active:bg-neutral-25",
        marigold:
          "text-neutral-0 bg-marigold-100 hover:bg-marigold-525 active:bg-marigold-750",
        primary:
          "text-neutral-0 bg-primary-800 hover:bg-primary-825 active:bg-primary-850",
        purple:
          "text-neutral-0 bg-purple-900 hover:bg-purple-850 active:bg-purple-775",
      },
      size: {
        sm: "rounded-s p-3s",
        lg: "rounded-m p-m min-h-[320px]",
      },
    },
    defaultVariants: {
      variant: "primary",
      size: "lg",
    },
  },
);

export type CardButtonProps = {
  href?: string | null;
  className?: string;
  id?: string;
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  testId?: string;
} & VariantProps<typeof buttonStyles>;

const CardButton: FC<PropsWithChildren<CardButtonProps>> = ({
  children,
  ...props
}) => {
  return (
    <CardButtonContext.Provider value={props}>
      <CardButtonComponent {...props}>{children}</CardButtonComponent>
    </CardButtonContext.Provider>
  );
};

const CardButtonComponent: FC<PropsWithChildren<CardButtonProps>> = ({
  href,
  variant,
  children,
  className,
  id,
  onClick,
  testId,
  size,
}) => {
  if (href) {
    return (
      <Link
        data-testid={testId}
        onClick={onClick}
        href={href}
        id={id}
        className={buttonStyles({ size, variant, className })}
      >
        {children}
      </Link>
    );
  } else {
    return (
      <button
        data-testid={testId}
        onClick={onClick}
        type="button"
        id={id}
        className={buttonStyles({ size, variant, className })}
      >
        {children}
      </button>
    );
  }
};

export default Object.assign(CardButton, {
  Title: CardButtonTitle,
  Body: CardButtonBody,
  Footer: CardButtonFooter,
});
