import clsx from "clsx";
import { FC, PropsWithChildren, useContext } from "react";
import { CardButtonContext, getCardButtonColors } from "./utilities";

export interface CardButtonBodyProps {
  className?: string;
}
const CardButtonBody: FC<PropsWithChildren<CardButtonBodyProps>> = ({
  children,
  className,
}) => {
  const { variant, size } = useContext(CardButtonContext);
  if (!children) {
    return null;
  }
  const textColor = getCardButtonColors("body", variant);
  return (
    <div
      className={clsx(
        "text-body-2 grow mt-2xs",
        {
          "text-body-2": size === "lg",
          "text-app-body-1": size === "sm",
        },
        textColor,
        className,
      )}
    >
      {children}
    </div>
  );
};

export default CardButtonBody;
