import clsx from "clsx";
import { useRouter } from "next/router";
import { FC, MouseEvent, useCallback } from "react";
import style from "./style.module.css";

interface RichTextProps {
  children?: string | null;
  inverted?: boolean;
  size?: "lg" | "md" | "sm";
  className?: string;
}

export const RichText: FC<RichTextProps> = ({
  children,
  inverted,
  size = "lg",
  className,
}) => {
  const router = useRouter();
  const handleClick = useCallback(
    (event: MouseEvent) => {
      const target = event.target as HTMLAnchorElement;
      const host = `${window.location.protocol}//${window.location.hostname}`;

      if (
        target.tagName === "A" &&
        (target.href.startsWith("/") || target.href.startsWith(host))
      ) {
        router.push(target.href);
        event.preventDefault();
      }
    },
    [router],
  );

  return (
    <div
      role="none"
      onClick={handleClick}
      className={clsx(style.content, className, {
        "text-neutral-0": inverted,
        [style.contentBody1 as string]: size === "lg",
        [style.contentBody2 as string]: size === "md",
        [style.contentAppBody1 as string]: size === "sm",
      })}
      dangerouslySetInnerHTML={{ __html: children || "" }}
    ></div>
  );
};

export default RichText;
