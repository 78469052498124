import clsx from "clsx";
import { FC, PropsWithChildren, useContext } from "react";
import { CardButtonContext, getCardButtonColors } from "./utilities";

const CardButtonTitle: FC<PropsWithChildren & { className?: string }> = ({
  children,
  className,
}) => {
  const { variant, size } = useContext(CardButtonContext);
  if (!children) {
    return null;
  }
  const textColor = getCardButtonColors("title", variant);
  return (
    <div
      className={clsx(
        textColor,
        {
          "text-app-h2": size === "sm",
          "text-h2": size === "lg",
        },
        className,
      )}
    >
      {children}
    </div>
  );
};

export default CardButtonTitle;
