import clsx from "clsx";
import { FC, PropsWithChildren, useContext } from "react";
import Icon from "../Icon";
import { CardButtonContext, getCardButtonColors } from "./utilities";

const CardButtonFooter: FC<PropsWithChildren> = ({ children }) => {
  const { variant } = useContext(CardButtonContext);
  if (!children) {
    return null;
  }
  const textColor = getCardButtonColors("footer", variant);
  return (
    <div
      className={clsx(
        "text-button-1 font-medium align-middle flex items-center justify-between w-full mt-3s",
        textColor,
      )}
    >
      {children}
      <Icon
        type="RightSmallUp"
        className="group-hover:rotate-45 group-active:rotate-45 transition-all"
      />
    </div>
  );
};

export default CardButtonFooter;
