import { trackEvent } from "@justhome/common";
import { CardButton as BaseCardButton } from "@justhome/react-ui";
import { ComponentBlocksCardButton } from "@lib/generated/graphql";
import { getPageEntityPath } from "@lib/routing";
import { FC } from "react";

export const Button: FC<
  ComponentBlocksCardButton & {
    className?: string;
    testId?: string;
  }
> = ({
  title,
  variant,
  body,
  footer,
  href,
  page,
  className,
  trackingId,
  testId,
}) => {
  const pageHref = getPageEntityPath(page) ?? href;

  const handleClickCardButton = () => {
    if (trackingId) {
      trackEvent(trackingId);
    }
  };

  return (
    <BaseCardButton
      testId={testId}
      onClick={handleClickCardButton}
      variant={variant}
      className={className}
      href={pageHref}
      id={trackingId || undefined}
    >
      <BaseCardButton.Title>{title}</BaseCardButton.Title>
      <BaseCardButton.Body>{body}</BaseCardButton.Body>
      <BaseCardButton.Footer>{footer}</BaseCardButton.Footer>
    </BaseCardButton>
  );
};

export default Button;
