import ImageBlock from "@components/blocks/ImageBlock";
import { ComponentBlocksSectionImage, Maybe } from "@lib/generated/graphql";
import clsx from "clsx";
import { FC } from "react";

interface SectionImageProps {
  data?: Maybe<ComponentBlocksSectionImage>;
  index: number;
}

const SectionImage: FC<SectionImageProps> = ({ data, index }) => {
  const image = data?.image;
  if (!(data && image)) {
    return null;
  }

  const isTopAligned = image.position === "top" && data.paddingTop === "none";
  const isBottomAligned =
    image.position === "bottom" && data.paddingBottom === "none";
  const isOverflow = image.position === "overflow";

  return (
    <div
      className={clsx(
        "relative max-w-[540px] mx-auto m:max-w-none m:mx-none w-full",
        {
          "my-m m:my-none": !isTopAligned && !isBottomAligned && !isOverflow,
          "mb-m m:mb-none": isTopAligned,
          "mt-m m:mt-none": isBottomAligned,
          "m:pb-3s": data.paddingBottom === "sm",
          "m:pb-3xl": data.paddingBottom === "md",
          "m:pt-3s": data.paddingTop === "sm",
          "m:pt-3xl": data.paddingTop === "md",
          "m:-my-3s h-[calc(100%+48px)]": isOverflow,
          "m:h-full": !isOverflow,
        },
      )}
    >
      {data.image && (
        <ImageBlock
          image={data.image}
          loading={index === 0 ? "eager" : "lazy"}
        />
      )}
    </div>
  );
};

export default SectionImage;
