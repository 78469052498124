import { createContext } from "react";
import { CardButtonProps } from "./index";

export const CardButtonContext = createContext<CardButtonProps>({});

export const getCardButtonColors = (
  type: "title" | "body" | "footer",
  variant: CardButtonProps["variant"],
) => {
  const textColors = {
    primary: "text-neutral-0",
    purple: "text-mindaro-325",
    marigold: "",
  };
  if (type === "footer") {
    textColors.marigold = "text-primary-800";
  } else if (type === "body") {
    textColors.marigold = "text-neutral-575";
  } else {
    // title
    textColors.marigold = "text-neutral-675";
  }
  return textColors[(variant || "primary") as keyof typeof textColors];
};
