import { ComponentBlocksTwoColumnLayout, Maybe } from "@lib/generated/graphql";
import clsx from "clsx";
import React, { ElementType, FC, PropsWithChildren } from "react";

export interface TwoColumnLayoutProps<T extends ElementType = "div"> {
  data?: Maybe<ComponentBlocksTwoColumnLayout>;
  as?: T;
}

export interface TwoColumnLayoutColumnProps {
  data?: Maybe<ComponentBlocksTwoColumnLayout>;
  className?: string;
}

const getColClass = (cols: number) => `col-span-${cols}`;

const FirstColumn: FC<PropsWithChildren<TwoColumnLayoutColumnProps>> = ({
  data,
  children,
  className,
}) => {
  return (
    <div
      className={clsx(className, getColClass(data?.firstColumn || 6), {
        "col-end-13": data?.direction === "reverse",
        "col-start-1": data?.direction === "normal",
      })}
    >
      {children}
    </div>
  );
};

const SecondColumn: FC<PropsWithChildren<TwoColumnLayoutColumnProps>> = ({
  data,
  children,
  className,
}) => {
  return (
    <div
      className={clsx(className, getColClass(data?.secondColumn || 6), {
        "col-end-13": data?.direction === "normal",
        "col-start-1 row-start-1": data?.direction === "reverse",
      })}
    >
      {children}
    </div>
  );
};

const TwoColumnLayout = <T extends ElementType = "div">({
  data,
  as,
  children,
  ...props
}: PropsWithChildren<TwoColumnLayoutProps<T>> &
  Omit<React.ComponentPropsWithoutRef<T>, keyof TwoColumnLayoutProps<T>>) => {
  const Component: ElementType = as || "div";

  return (
    <Component
      {...props}
      className={clsx(
        props.className,
        "flex flex-col m:grid grid-cols-12 gap justify-between",
      )}
    >
      {children}
    </Component>
  );
};

export default Object.assign(TwoColumnLayout, {
  FirstColumn,
  SecondColumn,
});
